<template>
  <div class="page-wrapper">
    <privacy-header />
    <generic-section height="auto">
      <div v-html="privacyContent" />
    </generic-section>
  </div>
</template>

<script>
import GenericSection from "@/components/generic/GenericSection";
import PrivacyHeader from "@/pages/routes/PrivacyPolicy/PrivacyHeader";
import { ref } from "vue";
export default {
  name: "index",
  components: {
    "generic-section": GenericSection,
    "privacy-header": PrivacyHeader
  },
  setup() {
    const privacyContent = ref("");
    fetch("https://storage.googleapis.com/static.cdwscan.com/privacy_updated.html").then(res => res.text()).then(response => {
      privacyContent.value = response;
    })
    return {
      privacyContent
    }
  }
}
</script>

<style scoped>
  :deep(p), :deep(ul) {
    text-align: start;
  }
  .deep(p > a) {
    display: none;
  }

  .page-wrapper {
    width: 100vw;
    box-sizing: border-box;
  }
  @media screen and (max-width: 992px) {
    .page-wrapper {
      padding: 25px;
    }
  }
</style>